import React from 'react'
import style from './AboutUs.module.css'
import about_image from '../../Assets/images/ring_alarm_keypad_night_desktop_2x 1aout.png'
import AboutUsCard from '../../components/AboutUsPageCard/AboutUsCard'
import icon1 from '../../Assets/icons/Vector.png'
import icon3 from '../../Assets/icons/Vectoraboutpage-icon.png'
import TeamCard from '../../components/TeamCard/TeamCard'
import linkedin from '../../Assets/icons/linkedin-dark.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import Founder1Pic from '../../Assets/images/founder.jpg'
import Founder2Pic from '../../Assets/images/Founder.jfif'
import TeamPicPrasad from '../../Assets/images/founder2.jpg'
import TeamPicYogesh from '../../Assets/images/founder3.jpg'
import Advisor1 from '../../Assets/images/advisor.jpg'
import Advisor2 from '../../Assets/images/advisor2.jpg'
import Advisor3 from '../../Assets/images/advisor3.jpg'
import Advisor4 from '../../Assets/images/advisor4.jpg'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './swiper.css'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { useEffect } from 'react'
import FounderCard from '../../components/FounderCard/FounderCard'
import AboutTestimonial from '../../components/AboutPageTestimonial/AboutTestimonial'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import Footer from '../../components/Footer/Footer'
import { Testimonial } from '../../services/contact'
import { useState } from 'react'
import { getAdvisors, getFounders, getTeam } from '../../services/aboutApi'
import { BASE_URL } from '../../utils/constant'
// import "./styles.css";
const AboutUs = () => {
  const { width } = useWindowDimensions()
  const [SayAboutUs, setSayAboutUs] = useState([])
  const [founders,setFounders] = useState([])
  const [team,setTeam] = useState([])
  const [advisors,setAdvisors] = useState([])
  const FetchAboutUs = async () => {
    const data = await Testimonial()
    // console.log(data)
    if (data?.results?.length >= 1) {
      setSayAboutUs(data?.results?.slice(0, 3))
    }
  }
  const fetchFounders = async ()=>{
    const data = await getFounders()
    setFounders(data)
    // console.log(data)
  }
  const fetchTeam = async ()=>{
    const data = await getTeam()
    setTeam(data)
    // console.log(data)
  }
  const fetchAdvisors = async ()=>{
    const data = await getAdvisors()
    setAdvisors(data)
    console.log(data)
  }

  useEffect(() => {
    const naviagtor = document.querySelectorAll('.swiper-pagination-bullets')
    naviagtor.forEach((each, idx) => {
      each.id = `navigator${idx}`
    })
    const first_next_btn = document.querySelector('.swiper-button-next')
    const first_prev_btn = document.querySelector('.swiper-button-prev')
    first_next_btn.id = 'swiper-next-bnt'
    first_prev_btn.id = 'swiper-prev-bnt'
    FetchAboutUs()
    fetchFounders()
    fetchTeam()
    fetchAdvisors()
  }, [])

  return (
    <>
      <div className={style['main-about-us-container']}>
        <div className={style['about-data-container-holder']}>
          <div className={style['about-data-container']}>
            <div className={style['about-heading']}>
              <p>Who we are</p>
            </div>
            <div className={style['about-para-container']}>
              <p> Our company specializes in valuations and fund-raising for startups and we have provided these services to over 100 startups in a short period of time. On the journey, we came across the idea of raising funds for smaller valued players by giving them a gateway to get access to retail investors, angel investors and VCs at one roof.</p>

              <p> Venturebuddy - a bridge for Investors and Startups where investors get the value driven startups and startups get the investment easily leading their ideas to come into reality.</p>
            </div>
            <div className={style['about-para-container']}>
              <p> It is a platform where retail investors get access to invest by being part of the change and, Startups looking to raise funds without affecting cap table. </p>
            </div>
          </div>
          <div className={style['about-page-image']}>
            <img src={about_image} alt="About Us Page" />
          </div>
        </div>
        <div className={style['future-prospect-container']}>
          <div className={style['future-heading']}>
            <p>Our Mission and Our Vision</p>
          </div>
          <div className={style['future-prospect-card-holder']}>
            <AboutUsCard card_data={{ logo: icon3, heading: 'Mission', para: "Building world-class crowdfunding and valuation platform, with enabling access to everyone to be part of India's startup story." }} />
            <AboutUsCard card_data={{ logo: icon1, heading: 'Vision', para: 'Our vision is to democratize - raise, invest and provide value to the startup ecosystem of India.' }} />
          </div>
        </div>
        {/* Testimonail Swiper */}
        <div className={style['testimonial-container']}>
          <div className={style['testimonial-swiper']}>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {SayAboutUs?.map((item, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <AboutTestimonial name={item?.client_name} position={item?.client_type} des={item?.client_opinion} img={item?.client_pic} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
        {/* Founder Swiper */}
        <div className={style['founder-container']}>
          <div className={style['team-heading']}>
            <p>Our Founders</p>
          </div>
          <div className={style['founder-swiper']}>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              {/* <SwiperSlide>
                <FounderCard team_data={founders} />
              </SwiperSlide> */}
              {
                founders?.map((item,e)=>(
              <SwiperSlide>
                <FounderCard team_data={{ pic: `${BASE_URL}${item?.profile_pic}`, name: item?.name, proffesion: [item?.profession1,item?.profession2,item?.profession3], icon: linkedin, data: '', url: item?.linkden }} />
              </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>

        {/* Team Container */}
        <div className={style['team-container']}>
          <div className={style['team-heading']}>
            <p id={style['team']}>Team</p>
          </div>
          <div className={style['team-swiper']}>
            <Swiper
              slidesPerView={width < 1000 ? 1 : 3}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
            {
              team?.map((item,e)=>(
              <SwiperSlide>
                <TeamCard team_data={{ pic: `${BASE_URL}${item?.profile_pic}`, name: item?.name, proffesion: `${item?.profession1}, ${item?.profession2}, ${item?.profession3}`, icon: linkedin, data: '', url: item?.linkden }} />
              </SwiperSlide>
              ))
            }
            </Swiper>
          </div>
        </div>
        {/* Mentor Container */}
        <div className={style['team-container']}>
          <div className={style['team-heading']}>
            <p id={style['margin-none']}>Advisers</p>
          </div>
          <div className={style['mentor-swiper']}>
            <Swiper
              slidesPerView={width < 1000 ? 1 : 3}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
            {
              advisors?.map((item,e)=>(
              <SwiperSlide>
                <TeamCard team_data={{ pic: `${BASE_URL}${item?.profile_pic}`, name: item?.name, proffesion: item?.role, icon: linkedin, data: '', url: item?.linkden }} />
              </SwiperSlide>
              ))
            }
            </Swiper>
          </div>
        </div>
        {/* Mentor Container End */}
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
